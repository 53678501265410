import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Map, InfoWindow, GoogleApiWrapper } from 'google-maps-react';
import { gMapApiKey } from '../../constants/config';
import { MarkerClusterer } from './cluster';
import { onBoundsChanged } from './map-utils';

const MapContainer = ({
  google,
  data,
  studyLocations,
  setActiveLoc,
  isDynamic = false,
}) => {
  const [activeMarker, setActiveMarker] = useState(null);
  const [isInfoWindowVisible, showInfoWindow] = useState(false);
  const mapRef = useRef();
  const clusterRef = useRef();
  const markers = useRef([]);
  const uniquePins = useRef([]);
  const markerDescription = useRef([]);
  const activeMarkerRef = useRef();

  useEffect(
    () => {
      let bounds = new window.google.maps.LatLngBounds();
      if (studyLocations) {
        studyLocations.forEach((location) => {
          if (location.Latitude && location.Longitude) {
            const key =
              '' +
              parseInt(location.Latitude * 10000) +
              '_' +
              parseInt(location.Longitude * 10000);
            if (typeof markerDescription.current[key] === 'undefined') {
              markerDescription.current[key] = [];
            }
            markerDescription.current[key].push(location);

            const loc = new window.google.maps.LatLng(
              location.Latitude,
              location.Longitude
            );
            bounds.extend(loc);

            const marker = new window.google.maps.Marker({
              position: new window.google.maps.LatLng(
                location.Latitude,
                location.Longitude
              ),
              title: isDynamic
                ? location.Name
                : `${location.StudyId} - Study Location Details`,
              markersClusterKey: key,
              draggable: false,
              lat: location.Latitude,
              long: location.Longitude,
              icon: {
                url: '/icons/locationMarker.png',
                scaledSize: new google.maps.Size(60, 60),
                anchor: new google.maps.Point(30, 50),
                origin: new google.maps.Point(0, 0),
              },
            });
            marker.setMap(mapRef.current.map);
            marker.addListener('click', () => {
              onMarkerClick(marker);
            });
            if (!uniquePins.current.includes(key)) {
              uniquePins.current.push(key);
              markers.current.push(marker);
            }
          }
        });
      }

      if (clusterRef.current === undefined) {
        mapRef.current.map.fitBounds(bounds);
        let zoom = mapRef.current.map.getZoom();
        mapRef.current.map.minZoom = 1;
        mapRef.current.map.setZoom(zoom > 6 ? 6 : zoom);
        clusterRef.current = new MarkerClusterer(
          mapRef.current.map,
          markers.current,
          {
            minimumClusterSize: 2,
            averageCenter: true,
            zoomOnClick: true,
            imagePath:
              'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
          }
        );
      }
      if (isDynamic) {
        if (
          mapRef &&
          mapRef.current &&
          mapRef.current.map !== null &&
          typeof mapRef.current.map !== 'undefined'
        ) {
          mapRef.current.map.addListener('bounds_changed', () => {
            setActiveLoc(
              onBoundsChanged(
                studyLocations,
                markers.current,
                mapRef.current.map
              )
            );
          });
        }
      }

      return () => {
        markers.current.forEach((marker) => {
          clusterRef.current.removeMarker(marker);
        });
        markerDescription.current = [];
        markers.current = [];
        uniquePins.current = [];
        clusterRef.current = undefined;
      };
    },
    isDynamic ? [] : [studyLocations]
  );

  const onMarkerClick = (marker) => {
    mapRef.current.map.setCenter(marker.getPosition());
    setActiveMarker(marker);
    showInfoWindow(true);
  };

  const onInfoWindowClose = () => {
    activeMarkerRef.current = null;
    showInfoWindow(false);
  };

  const onMapClicked = () => {
    if (isInfoWindowVisible) {
      activeMarkerRef.current = null;
      showInfoWindow(false);
    }
  };

  return (
    <Map
      ref={mapRef}
      google={google}
      containerStyle={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
      initialCenter={{
        lat:
          parseFloat(
            studyLocations.length ? studyLocations[0].Latitude : 50.43
          ) || 50.43,
        lng:
          parseFloat(
            studyLocations.length ? studyLocations[0].Longitude : 26.08
          ) || 26.081807,
      }}
      zoom={3}
      mapTypeControl={false}
      onClick={onMapClicked}
    >
      <InfoWindow
        maxWidth={350}
        height={180}
        marker={activeMarker}
        onClose={onInfoWindowClose}
        visible={isInfoWindowVisible}
      >
        {activeMarker &&
        activeMarker.markersClusterKey &&
        markerDescription.current[activeMarker.markersClusterKey] ? (
          markerDescription.current[activeMarker.markersClusterKey].map(
            (locationDetails, idx) => (
              <div className="marker-text" key={idx}>
                <strong>
                  {locationDetails.Name !== null &&
                  locationDetails.Name.length > 0
                    ? locationDetails.Name
                    : data.site}
                </strong>
                <br />
                <strong>{data.condition}</strong>: {locationDetails.conditions}
                <br />
                <strong>{data.id}</strong>: {locationDetails.StudyId}
                <br />
                <strong>{data.city}</strong>:{' '}
                {locationDetails.City != null
                  ? locationDetails.City
                  : 'Not Available'}
                <br />
                <strong>{data.state}</strong>:{' '}
                {locationDetails.StateDisplay != null
                  ? locationDetails.StateDisplay
                  : 'Not Available'}
                <br />
                <strong>{data.zip}</strong>:{' '}
                {locationDetails.PostalCode != null
                  ? locationDetails.PostalCode
                  : 'Not Available'}
                <br />
                <strong>{data.country}</strong>:{' '}
                {locationDetails.CountryDisplay != null
                  ? locationDetails.CountryDisplay
                  : 'Not Available'}
              </div>
            )
          )
        ) : (
          <div />
        )}
      </InfoWindow>
    </Map>
  );
};

MapContainer.propTypes = {
  data: PropTypes.object,
  studyLocations: PropTypes.array,
  conditons: PropTypes.string,
  google: PropTypes.object.isRequired,
  setActiveLoc: PropTypes.func,
  isDynamic: PropTypes.bool,
};

export default GoogleApiWrapper({
  apiKey: gMapApiKey(),
})(MapContainer);
